@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  min-height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", serif;
  /* background: linear-gradient(135deg, #79b574, #34ae23); */
  background-color: #0c6820;
  /* background: #fbfbfb; */
  color: #fff;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* BrandIcon.css */

/* Base styles for the brand text */
.brand-icon {
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
}

.brand-letter {
  display: inline-block;
  opacity: 0;
  transform: rotateX(90deg);
  animation: flipIn 4s infinite forwards;
}

.brand-letter:nth-child(1) {
  animation-delay: 0.2s;
}
.brand-letter:nth-child(2) {
  animation-delay: 0.4s;
}
.brand-letter:nth-child(3) {
  animation-delay: 0.6s;
}
.brand-letter:nth-child(4) {
  animation-delay: 0.8s;
}
.brand-letter:nth-child(5) {
  animation-delay: 1s;
}
.brand-letter:nth-child(6) {
  animation-delay: 1.2s;
}


/* Flip-in keyframes for the vertical flip effect */
@keyframes flipIn {
  0% {
    opacity: 0;
    transform: rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg); /* Letter flips into view */
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-in {
  animation: slide-in 1.5s ease-in-out;
}

.animate-bounce-x {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hero {
  height: 475px;
}
@media (max-width: 768px) {
  .hero {
    height: auto;
  }
}

.workflow-arrow {
  opacity: 0;
}

.visible-arrow {
  opacity: 1;
}


.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 16px;
}

.portofolio-card{
  border: 1px solid green;
}

/* Section Styling */
.mission-vision-section {
  background-color: #f0fff4;
  padding: 3rem 1rem;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2f855a;
  margin-bottom: 2rem;
}

/* Card Container */
.card-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Card Styling */
.card {
  width: 350px;
  height: 200px;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

/* Front and Back Faces */
.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #2f855a;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 128, 0, 0.2);
  color: #2f855a;
  font-size: 1.2rem;
  font-weight: bold;
}

.card-front {
  background-color: #ffffff;
}

.card-back {
  background-color: #e6fffa;
  transform: rotateY(180deg);
  text-align: center;
  padding: 1rem;
}

.workflow-step {
  flex: 1;
}

.step-icon {
  font-size: 2rem;
}

.workflow-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}


